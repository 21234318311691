<template>
  <div class="medium-8 columns">
    <loading-state v-if="showLoader" :message="emptyStateMessage" />
    <div class="login-init" v-else>
      <a href="#" @click="startLogin()">
        <img
          src="~dashboard/assets/images/channels/facebook_login.png"
          alt="Facebook-logo"
        />
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import LoadingState from 'dashboard/components/widgets/LoadingState';
import ChannelApi from '../../../../../api/channels';
import accountMixin from '../../../../../mixins/account';

export default {
  mixins: [alertMixin, accountMixin],
  components: {
    LoadingState,
  },
  props: {
    createNew: {
      type: Boolean,
      default: true,
    },
    inbox: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      isCreating: false,
      emptyStateMessage: this.$t('INBOX_MGMT.DETAILS.LOADING_FB'),
      hasLoginStarted: false,
    };
  },
  computed: {
    showLoader() {
      return this.hasLoginStarted || this.isCreating;
    },
    ...mapGetters({
      currentUser: 'getCurrentUser',
      globalConfig: 'globalConfig/get',
    }),
  },
  created() {
    this.initFB();
    this.loadFBsdk();
  },

  mounted() {
    this.initFB();
  },
  methods: {
    startLogin() {
      this.hasLoginStarted = true;
      this.tryFBlogin();
    },

    initFB() {
      if (window.fbSDKLoaded === undefined) {
        window.fbAsyncInit = () => {
          FB.init({
            appId: window.socialAppConfig.fbAppId,
            xfbml: true,
            version: 'v17.0',
            status: true,
          });
          window.fbSDKLoaded = true;
          FB.AppEvents.logPageView();
        };
      }
    },

    loadFBsdk() {
      ((d, s, id) => {
        let js;
        // eslint-disable-next-line
        const fjs = (js = d.getElementsByTagName(s)[0]);
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = '//connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    },

    tryFBlogin() {
      FB.login(
        response => {
          if (response.status === 'connected') {
            this.getWhatsappInfo(response.authResponse.code);
          } else if (response.status === 'not_authorized') {
            this.emptyStateMessage = this.$t(
              'INBOX_MGMT.DETAILS.ERROR_FB_AUTH'
            );
          } else {
            this.emptyStateMessage = this.$t(
              'INBOX_MGMT.DETAILS.ERROR_FB_AUTH'
            );
          }
        },
        {
          config_id: this.globalConfig.fbEmbeddedConfigID, // configuration ID goes here
          response_type: 'code', // must be set to 'code' for System User access token
          override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
          extras: {
            feature: 'whatsapp_embedded_signup',
          },
        },
        {
          scope:
            'whatsapp_business_management,business_management,whatsapp_business_messaging,public_profile',
        }
      );
    },

    async getWhatsappInfo(_token) {
      try {
        const response = await ChannelApi.fetchWhatsappInfo(
          _token,
          this.accountId
        );
        this.createNew
          ? this.createChannel(response.data.phone_numbers[0])
          : this.update(response.data.phone_numbers[0]);
      } catch (error) {
        // Ignore error
      }
    },

    async createChannel(data) {
      try {
        this.emptyStateMessage = this.$t('INBOX_MGMT.DETAILS.CREATING_CHANNEL');
        this.isCreating = true;

        const whatsappChannel = await this.$store.dispatch(
          'inboxes/createChannel',
          {
            name: data.display_phone_number.split(' ').join(''),
            channel: {
              type: 'whatsapp',
              phone_number: data.display_phone_number,
              provider: 'whatsapp_cloud',
              provider_config: {
                api_key: data.access_token,
                phone_number_id: data.phone_number_id,
                business_account_id: data.business_id,
                register_type: 'signup_flow',
              },
            },
          }
        );

        this.$router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: whatsappChannel.id,
          },
        });
      } catch (error) {
        this.isCreating = false;
        this.showAlert(
          this.$t('INBOX_MGMT.ADD.WHATSAPP.API.ERROR_MESSAGE'),
          'error'
        );
      }
    },

    async update(data) {
      if (+this.inbox.phone_number == +data.display_phone_number) {
        console.log('data', data);
        try {
          const payload = {
            id: this.inbox.id,
            formData: false,
            channel: {
              phone_number: data.display_phone_number,
              provider: 'whatsapp_cloud',
              provider_config: {
                api_key: data.access_token,
                phone_number_id: data.phone_number_id,
                business_account_id: data.business_id,
                register_type: 'signup_flow',
              },
            },
          };
          await this.$store.dispatch('inboxes/updateInbox', payload);
          this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
        } catch (error) {
          this.showAlert(this.$t('INBOX_MGMT.EDIT.API.ERROR_MESSAGE'), 'error');
        }
      } else {
        this.showAlert('The two numbers should be the same', 'error');
      }
    },
  },
};
</script>
