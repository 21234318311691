<template>
  <div
    v-if="!authUIFlags.isFetching"
    id="app"
    class="app-wrapper app-root"
    :class="{ 'app-rtl--wrapper': isRTLView && !isInWorkflow }"
  >
    <Banners v-if="account.status == 'active'" />

    <update-banner :latest-version="latestVersion" />
    <template v-if="!accountUIFlags.isFetchingItem && currentAccountId">
      <payment-pending-banner />
      <upgrade-banner />
    </template>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <add-account-modal
      v-if="globalConfig.createNewAccountFromDashboard"
      :show="showAddAccountModal"
      :has-accounts="hasAccounts"
    />
    <woot-snackbar-box />
    <network-notification />
    <Custom-notifications-list />
  </div>
  <loading-state v-else />
</template>

<script>
import { mapGetters } from 'vuex';
import AddAccountModal from '../dashboard/components/layout/sidebarComponents/AddAccountModal';
import LoadingState from './components/widgets/LoadingState.vue';
import NetworkNotification from './components/NetworkNotification';
import UpdateBanner from './components/app/UpdateBanner.vue';
import Banners from './components/app/Banners.vue';
import UpgradeBanner from './components/app/UpgradeBanner.vue';
import PaymentPendingBanner from './components/app/PaymentPendingBanner.vue';
import vueActionCable from './helper/actionCable';
import WootSnackbarBox from './components/SnackbarContainer';
import rtlMixin from 'shared/mixins/rtlMixin';
import {
  registerSubscription,
  verifyServiceWorkerExistence,
} from './helper/pushHelper';
import CustomNotificationsList from './components/notifications/CustomNotificationsList.vue';

export default {
  name: 'App',

  components: {
    AddAccountModal,
    LoadingState,
    NetworkNotification,
    UpdateBanner,
    PaymentPendingBanner,
    WootSnackbarBox,
    UpgradeBanner,
    Banners,
    CustomNotificationsList,
  },

  mixins: [rtlMixin],

  data() {
    return {
      showAddAccountModal: false,
      latestVersion: null,
      exceededActiveConversations: false,
      spendingGraceMacs: false,
      subscriptionType: '',
      grace_remaining_days: 0,
      endDate: null,
      limits: {},
    };
  },

  computed: {
    ...mapGetters({
      getAccount: 'accounts/getAccount',
      currentUser: 'getCurrentUser',
      globalConfig: 'globalConfig/get',
      authUIFlags: 'getAuthUIFlags',
      accountUIFlags: 'accounts/getUIFlags',
      currentAccountId: 'getCurrentAccountId',
    }),
    account() {
      return this.getAccount(this.currentAccountId);
    },
    hasAccounts() {
      const { accounts = [] } = this.currentUser || {};
      return accounts.length > 0;
    },
    hideWhenSuspended() {
      return this.$route.path.includes('suspended');
    },
    isInWorkflow() {
      return this.$route.name === 'settings_workflows_builder';
    },
  },

  watch: {
    currentUser() {
      if (!this.hasAccounts) {
        this.showAddAccountModal = true;
      }
    },
    currentAccountId() {
      if (this.currentAccountId) {
        this.initializeAccount();
      }
    },
  },
  mounted() {
    this.setLocale(window.socialAppConfig.selectedLocale);
  },
  methods: {
    setLocale(locale) {
      this.$root.$i18n.locale = locale;
    },
    async initializeAccount() {
      await this.$store.dispatch('accounts/get');
      this.$store.dispatch('setActiveAccount', {
        accountId: this.currentAccountId,
      });
      const {
        locale,
        latest_version: latestVersion,
        exceeded_active_conversations: exceededActiveConversations,
        spending_grace_macs: spendingGraceMacs,
        active_subscription: activeSubscription,
        limits: limits,
      } = this.getAccount(this.currentAccountId);

      this.limits = limits;
      this.subscriptionType =
        activeSubscription && activeSubscription.subscription_type;

      this.grace_remaining_days =
        activeSubscription && activeSubscription.grace_remaining_days;

      const { pubsub_token: pubsubToken } = this.currentUser || {};
      this.setLocale(locale);
      this.latestVersion = latestVersion;
      this.exceededActiveConversations = exceededActiveConversations;
      this.spendingGraceMacs = spendingGraceMacs;
      this.endDate = this.getAccount(
        this.currentAccountId
      )?.active_subscription?.end_date;
      vueActionCable.init(pubsubToken);

      verifyServiceWorkerExistence(registration =>
        registration.pushManager.getSubscription().then(subscription => {
          if (subscription) {
            registerSubscription();
          }
        })
      );
    },
  },
};
</script>

<style lang="scss">
@import './assets/scss/app';
// start custom scroll
/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--b-50);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--b-500);
  border-radius: 25px;
  transition: background 0.3s ease-in-out;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8e8e8e;
}
// end custom scroll

// edits on our widget button
.woot-widget-bubble {
  display: none;
  &.woot-elements--left {
    left: 20px;
  }

  &.woot-elements--right {
    right: 20px;
  }
}
.header-expanded {
  .actions .close-button {
    display: block !important;
  }
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
