import ApiClient from './ApiClient';

class Zoho extends ApiClient {
  constructor() {
    super('integrations/zoho_actions', { accountScoped: true });
  }

  getSuggestContacts(contactId) {
    return axios.get(`${this.url}/contacts/suggest?contact_id=${contactId}`);
  }

  getContactsByName({ query, from }) {
    return axios.get(
      `${this.url}/contacts/search?fullName=${query}&from=${from}`
    );
  }

  createZohoContact({ firstName, lastName, email, phone }) {
    return axios.post(`${this.url}/contacts`, {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
    });
  }

  linkContact({ contactId, bevaContactId }) {
    return axios.post(`${this.url}/contacts/${contactId}/link`, {
      contact_id: bevaContactId,
    });
  }

  editLinkedContact({ id, ...payload }) {
    return axios.patch(`${this.url}/contacts/${id}`, {
      ...payload,
    });
  }

  unLinkContact({ contactId, bevaContactId, deleteContact }) {
    return axios.post(`${this.url}/contacts/${contactId}/unlink`, {
      contact_id: bevaContactId,
      delete_zoho_contact: deleteContact,
    });
  }

  getLinkedContact(contactId) {
    return axios.get(`${this.url}/contacts/${contactId}`);
  }

  getAllTickets(params) {
    return axios.get(`${this.url}/tickets/search`, { params });
  }
  createTicket(ticketFormData) {
    return axios.post(`${this.url}/tickets`, ticketFormData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }
  getTicket(ticketId) {
    return axios.get(`${this.url}/tickets/${ticketId}`);
  }
  update(params) {
    return axios.patch(`${this.url}/tickets/${params.id}`, {
      ...params.ticketParams,
    });
  }
  addComment({ id, comment }) {
    return axios.post(`${this.url}/tickets/${id}/comment `, {
      content: comment,
      isPrivate: false,
    });
  }
  getDepartments() {
    return axios.get(`${this.url}/departments`);
  }
  getDownloadLink(attachLink) {
    return axios.get(`${this.url}/tickets/download?zoho_url=${attachLink}`);
  }
}

export default new Zoho();
