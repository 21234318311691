<template>
  <div class="column page-top-bar">
    <div class="flex-grow-1">
      <h2 class="page-sub-title">
        {{ headerTitle }}
      </h2>
      <p v-if="headerContent" class="small-12 column wrap-content">
        {{ headerContent }}
        <span v-if="headerContentValue" class="content-value">
          {{ headerContentValue }}
        </span>
      </p>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    headerTitle: {
      type: String,
      default: '',
    },
    headerContent: {
      type: String,
      default: '',
    },
    headerContentValue: {
      type: String,
      default: '',
    },
  },
};
</script>
<style scoped lang="scss">
.page-top-bar {
  padding: 0;
  display: flex;
  align-items: center;
  .page-sub-title{
    margin-bottom: 0;
  }
}
.wrap-content {
  word-wrap: break-word;
  margin-top: var(--space-small);

  .content-value {
    font-weight: var(--font-weight-bold);
  }
}
</style>
