import { frontendURL } from 'dashboard/helper/URLHelper.js';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/campaigns'),
      component: () => import('./Wrapper.vue'),
      props: {
        headerTitle: 'CAMPAIGN.HEADER',
        icon: 'megaphone',
        showSideMenuIcon: true,
        headerButtonText: 'CAMPAIGN.HEADER_BTN_TXT',
      },
      children: [
        {
          path: 'add',
          name: 'create_campaigns',
          props: route => {
            return { draftedCampaign: route.params.draftedCampaign || '' };
          },
          component: () => import('./components/CreateCampaign.vue'),
        },
        {
          path: '',
          name: 'all_campaigns',
          component: () => import('./Index.vue'),
          props: {
            campaignType: 'all',
          },
        },
        {
          path: 'whatsapp',
          name: 'whatsapp_campaigns',
          component: () => import('./Index.vue'),
          props: {
            campaignType: 'whatsapp',
          },
        },
        {
          path: 'sms',
          name: 'sms_campaigns',
          component: () => import('./Index.vue'),
          props: {
            campaignType: 'sms',
          },
        },
        {
          path: 'facebook',
          name: 'facebook_campaigns',
          component: () => import('./Index.vue'),
          props: {
            campaignType: 'facebook',
          },
        },
        {
          path: 'telegram',
          name: 'telegram_campaigns',
          component: () => import('./Index.vue'),
          props: {
            campaignType: 'telegram',
          },
        },
      ],
    },
  ],
};
