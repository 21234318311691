import { frontendURL } from '../../../../helper/URLHelper';
const iconImagePath = '/assets/images/dashboard/channels/'
const campaigns = accountId => ({
  parentNav: 'campaigns',
  routes: [
    'all_campaigns',
    'whatsapp_campaigns',
    'sms_campaigns',
    'website_campaigns',
    'facebook_campaigns',
    'telegram_campaigns',
    'create_campaigns',
  ],
  menuItems: [
    {
      key: 'allCampaigns',
      iconImagePath: `${iconImagePath}all_campaigns.svg`,
      label: 'ALL_CAMPAIGNS',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/campaigns`),
      toStateName: 'all_campaigns',
    },
    {
      key: 'whatsappCampaigns',
      iconImagePath: `${iconImagePath}whatsapp.png`,
      label: 'WHATSAPP',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/campaigns/whatsapp`),
      toStateName: 'whatsapp',
    },
    {
      key: 'smsCampaigns',
      iconImagePath: `${iconImagePath}sms.png`,
      label: 'SMS',
      hasSubMenu: false,
      comingSoon:true,
      toState: frontendURL(`accounts/${accountId}/campaigns/sms`),
      toStateName: 'sms_campaigns',
    },
    {
      key: 'facebookCampaigns',
      iconImagePath: `${iconImagePath}facebook.png`,
      label: 'FACEBOOK',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/campaigns/facebook`),
      toStateName: 'facebook_campaigns',
    },
    {
      key: 'telegramCampaigns',
      iconImagePath: `${iconImagePath}telegram.png`,
      label: 'TELEGRAM',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/campaigns/telegram`),
      toStateName: 'telegram_campaigns',
    },
  ],
});

export default campaigns;
