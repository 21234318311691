<template>
  <woot-modal
    :show.sync="show"
    :on-close="onClose"
    :header-title="$t('TEAMS_SETTINGS.ADD.TITLE')"
    :header-content="$t('TEAMS_SETTINGS.ADD.DESC')"
  >
    <div class="column content-box">
      <form class="row">
        <!-- Team Name Input  -->
        <div class="medium-12 columns">
          <label>
            {{ $t('TEAMS_SETTINGS.ADD.FORM.NAME.LABEL') }}<sup>*</sup>
            <input
              type="text"
              :class="{ error: $v.title.$error }"
              :placeholder="$t('TEAMS_SETTINGS.ADD.FORM.NAME.PLACEHOLDER')"
              v-model="title"
              @input="$v.title.$touch"
              maxlength="30"
            />
            <span class="input__hint"
              >{{ $t('TEAMS_SETTINGS.ADD.FORM.NAME.INPUT_HINT') }} ({{
                title.length
              }}/ 30)</span
            >
          </label>
          <div
            v-if="$v.title.$error"
            :class="{ error: $v.title.$error }"
            class="mt-2"
          >
            <span v-if="!$v.title.required && $v.title.$error" class="message">
              {{ $t('TEAMS_SETTINGS.ADD.FORM.NAME.ERROR.REQUIRED') }}
            </span>
            <span
              v-if="!$v.title.minLength && $v.title.required"
              class="message"
            >
              {{ $t('TEAMS_SETTINGS.ADD.FORM.NAME.ERROR.MIN_LENGTH') }}
            </span>
            <span
              v-if="!$v.title.maxLength && $v.title.required"
              class="message"
            >
              {{ $t('TEAMS_SETTINGS.ADD.FORM.NAME.ERROR.MAX_LENGTH') }}
            </span>
          </div>
        </div>

        <!-- Team Description Input  -->
        <div class="medium-12 columns">
          <label>
            {{ $t('TEAMS_SETTINGS.ADD.FORM.DESCRIPTION.LABEL') }}<sup>*</sup>
            <input
              type="text"
              :placeholder="
                $t('TEAMS_SETTINGS.ADD.FORM.DESCRIPTION.PLACEHOLDER')
              "
              v-model="description"
              @input="$v.description.$touch"
              maxlength="150"
            />
            <span class="input__hint"
              >{{ $t('TEAMS_SETTINGS.ADD.FORM.DESCRIPTION.INPUT_HINT') }} ({{
                description.length
              }}/ 150)</span
            >
          </label>

          <div
            v-if="$v.description.$error"
            :class="{ error: $v.description.$error }"
            class="mt-2"
          >
            <span
              v-if="!$v.description.required && $v.description.$error"
              class="message"
            >
              {{ $t('TEAMS_SETTINGS.ADD.FORM.DESCRIPTION.ERROR.REQUIRED') }}
            </span>
            <span
              v-if="!$v.description.maxLength && $v.description.required"
              class="message"
            >
              {{ $t('TEAMS_SETTINGS.ADD.FORM.DESCRIPTION.ERROR.MAX_LENGTH') }}
            </span>
          </div>
        </div>

        <!-- Add Agents To Team Select  -->
        <div class="medium-12 columns">
          <label class="relative">
            {{ $t('TEAMS_SETTINGS.ADD.FORM.AGENTS_SELECT.LABEL') }}<sup>*</sup>
            <div class="multiselect-wrap--small">
              <multiselect
                v-model="agentListSelected"
                :options="agentList"
                track-by="id"
                label="name"
                :multiple="true"
                :placeholder="
                  $t('TEAMS_SETTINGS.ADD.FORM.AGENTS_SELECT.PLACEHOLDER')
                "
                :close-on-select="false"
                :clear-on-select="false"
                :hide-selected="true"
                :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                selected-label
                :custom-label="customLabel"
                :allow-empty="false"
              >
                <template slot="singleLabel" slot-scope="props">
                  <thumbnail
                    :src="props.option.thumbnail"
                    size="34px"
                    :username="props.option.name"
                  />
                  <div class="option__desc">
                    <div class="option__title">{{ props.option.name }}</div>
                  </div>
                </template>

                <template slot="option" slot-scope="props">
                  <div class="option">
                    <thumbnail
                      :src="props.option.thumbnail"
                      size="34px"
                      :username="props.option.name"
                    />
                    <div class="option__desc">
                      <div class="option__title">{{ props.option.name }}</div>
                      <div class="option__small">{{ props.option.email }}</div>
                    </div>
                  </div>
                </template>
              </multiselect>
            </div>
          </label>
          <div
            v-if="$v.agentListSelected.$error"
            :class="{ error: $v.agentListSelected.$error }"
            class="mt-2"
          >
            <span
              v-if="
                !$v.agentListSelected.required && $v.agentListSelected.$error
              "
              class="message"
            >
              {{ $t('TEAMS_SETTINGS.ADD.FORM.AGENTS_SELECT.ERROR.REQUIRED') }}
            </span>
            <span
              v-if="
                !$v.agentListSelected.minLength && $v.agentListSelected.required
              "
              class="message"
            >
              {{ $t('TEAMS_SETTINGS.ADD.FORM.AGENTS_SELECT.ERROR.MIN_LENGTH') }}
            </span>
          </div>
        </div>

        <!-- allow auto assign checkbox  -->
        <div class="medium-12">
          <label>
            <input v-model="allowAutoAssign" type="checkbox" value="true" />
            {{ $t('TEAMS_SETTINGS.ADD.FORM.AUTO_ASSIGN.LABEL') }}
          </label>
        </div>
      </form>
    </div>

    <template v-slot:footer>
      <button class="button clear" @click.prevent="onClose">
        {{ $t('TEAMS_SETTINGS.ADD.CANCEL_BUTTON_TEXT') }}
      </button>
      <woot-submit-button
        type="submit"
        :button-text="$t('TEAMS_SETTINGS.ADD.FORM.SUBMIT_CREATE')"
        :loading="uiFlags.isCreating"
        :disabled="$v.title.$invalid || $v.description.$invalid"
        @click="handleSubmit"
      />
    </template>
  </woot-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import validations from './helpers/validations';
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';

export default {
  components: {
    Thumbnail,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      title: '',
      description: '',
      agentListSelected: [],
      agentListSelectedIds: [],
      allowAutoAssign: true,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'teams/getUIFlags',
      agentList: 'agents/getAgents',
    }),
  },
  methods: {
    customLabel({ name }) {
      return name;
    },
    showAlert(message, type) {
      bus.$emit('newToastMessage', message, type);
    },
    getAgentsIds() {
      this.agentListSelectedIds = this.agentListSelected.map(agent => agent.id);
    },
    async handleSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        this.getAgentsIds();
        await this.$store.dispatch('teams/create', {
          description: this.description,
          name: this.title,
          allow_auto_assign: this.allowAutoAssign,
          user_ids: this.agentListSelectedIds,
        });
        this.showAlert(this.$t('TEAMS_SETTINGS.ADD.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        this.showAlert(
          this.$t('TEAMS_SETTINGS.ADD.API.ERROR_MESSAGE'),
          'error'
        );
      }
    },
  },
  mounted() {
    this.$store.dispatch('agents/get');
  },
  validations,
};
</script>

<style lang="scss" scoped>
sup {
  color: var(--r-500);
  font-size: 12px;
}
input:has(~ span) {
  margin: 0 0 0.5rem;
}
.input__hint {
  display: block;
  color: var(--b-500);
  font-size: 12px;
  margin-bottom: 1rem;
}
.option {
  display: flex;
  gap: 10px;
}
.error {
  border-color: var(--r-400);
}
</style>
