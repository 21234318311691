export default {
  AUTHENTICATE: 'AUTHENTICATE',
  CLEAR_USER: 'LOGOUT',
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  SET_CURRENT_USER_AVAILABILITY: 'SET_CURRENT_USER_AVAILABILITY',
  SET_CURRENT_USER_UI_SETTINGS: 'SET_CURRENT_USER_UI_SETTINGS',
  SET_CURRENT_USER_UI_FLAGS: 'SET_CURRENT_USER_UI_FLAGS',

  // Chat List
  RECEIVE_CHAT_LIST: 'RECEIVE_CHAT_LIST',
  SET_ALL_CONVERSATION: 'SET_ALL_CONVERSATION',
  EMPTY_ALL_CONVERSATION: 'EMPTY_ALL_CONVERSATION',
  SET_CONV_TAB_META: 'SET_CONV_TAB_META',
  CLEAR_LIST_LOADING_STATUS: 'CLEAR_LIST_LOADING_STATUS',
  SET_LIST_LOADING_STATUS: 'SET_LIST_LOADING_STATUS',
  SET_ALL_MESSAGES_LOADED: 'SET_ALL_MESSAGES_LOADED',
  CLEAR_ALL_MESSAGES_LOADED: 'CLEAR_ALL_MESSAGES_LOADED',
  CHANGE_CHAT_STATUS_FILTER: 'CHANGE_CHAT_STATUS_FILTER',
  CHANGE_CHAT_SORT_FILTER: 'CHANGE_CHAT_SORT_FILTER',
  UPDATE_ASSIGNEE: 'UPDATE_ASSIGNEE',
  UPDATE_CONVERSATION_CONTACT: 'UPDATE_CONVERSATION_CONTACT',
  CLEAR_CONTACT_CONVERSATIONS: 'CLEAR_CONTACT_CONVERSATIONS',
  SET_CONVERSATION_FILTERS: 'SET_CONVERSATION_FILTERS',
  CLEAR_CONVERSATION_FILTERS: 'CLEAR_CONVERSATION_FILTERS',
  SET_CONVERSATION_LAST_SEEN: 'SET_CONVERSATION_LAST_SEEN',
  SET_LAST_MESSAGE_ID_IN_SYNC_CONVERSATION:
    'SET_LAST_MESSAGE_ID_FOR_SYNC_CONVERSATION',
  SET_ATTACHMENT_FLAG: 'SET_ATTACHMENT_FLAG',

  SET_CURRENT_CHAT_WINDOW: 'SET_CURRENT_CHAT_WINDOW',
  CLEAR_CURRENT_CHAT_WINDOW: 'CLEAR_CURRENT_CHAT_WINDOW',
  CLEAR_ALL_MESSAGES: 'CLEAR_ALL_MESSAGES',
  CHANGE_CONVERSATION_STATUS: 'CHANGE_CONVERSATION_STATUS',
  ADD_CONVERSATION: 'ADD_CONVERSATION',
  UPDATE_CONVERSATION: 'UPDATE_CONVERSATION',
  MUTE_CONVERSATION: 'MUTE_CONVERSATION',
  UNMUTE_CONVERSATION: 'UNMUTE_CONVERSATION',
  STOP_WORKFLOW: 'STOP_WORKFLOW',
  ASSIGN_AGENT: 'ASSIGN_AGENT',
  ASSIGN_TEAM: 'ASSIGN_TEAM',
  ASSIGN_PRIORITY: 'ASSIGN_PRIORITY',
  SET_CHAT_META: 'SET_CHAT_META',
  ADD_MESSAGE: 'ADD_MESSAGE',
  DELETE_MESSAGE: 'DELETE_MESSAGE',
  ADD_PENDING_MESSAGE: 'ADD_PENDING_MESSAGE',
  UPDATE_MESSAGE_UNREAD_COUNT: 'UPDATE_MESSAGE_UNREAD_COUNT',
  SET_PREVIOUS_CONVERSATIONS: 'SET_PREVIOUS_CONVERSATIONS',
  SET_ACTIVE_INBOX: 'SET_ACTIVE_INBOX',
  UPDATE_CONVERSATION_CUSTOM_ATTRIBUTES:
    'UPDATE_CONVERSATION_CUSTOM_ATTRIBUTES',
  UPDATE_CONVERSATION_LAST_ACTIVITY: 'UPDATE_CONVERSATION_LAST_ACTIVITY',
  SET_MISSING_MESSAGES: 'SET_MISSING_MESSAGES',

  SET_ALL_ATTACHMENTS: 'SET_ALL_ATTACHMENTS',
  ADD_CONVERSATION_ATTACHMENTS: 'ADD_CONVERSATION_ATTACHMENTS',
  DELETE_CONVERSATION_ATTACHMENTS: 'DELETE_CONVERSATION_ATTACHMENTS',

  SET_CONVERSATION_CAN_REPLY: 'SET_CONVERSATION_CAN_REPLY',

  // Inboxes
  SET_INBOXES_UI_FLAG: 'SET_INBOXES_UI_FLAG',
  SET_INBOXES: 'SET_INBOXES',
  SET_INBOX_ITEM: 'SET_INBOX_ITEM',
  ADD_INBOXES: 'ADD_INBOXES',
  EDIT_INBOXES: 'EDIT_INBOXES',
  DELETE_INBOXES: 'DELETE_INBOXES',

  // Agent
  SET_ACCOUNT_UI_FLAG: 'SET_ACCOUNT_UI_FLAG',
  SET_ACCOUNT_LIMITS: 'SET_ACCOUNT_LIMITS',
  SET_ACCOUNTS: 'SET_ACCOUNTS',
  ADD_ACCOUNT: 'ADD_ACCOUNT',
  EDIT_ACCOUNT: 'EDIT_ACCOUNT',
  DELETE_ACCOUNT: 'DELETE_AGENT',

  // block list
  GET_BLOCK_LIST: 'GET_BLOCK_LIST',
  UNBLOCK: 'UNBLOCK',
  UNBLOCKALL: 'UNBLOCKALL',
  SET_BLOCK_LIST_UI_FLAG: 'SET_BLOCK_LIST_UI_FLAG',

  // Agent
  SET_AGENT_FETCHING_STATUS: 'SET_AGENT_FETCHING_STATUS',
  SET_AGENT_CREATING_STATUS: 'SET_AGENT_CREATING_STATUS',
  SET_AGENT_UPDATING_STATUS: 'SET_AGENT_UPDATING_STATUS',
  SET_AGENT_DELETING_STATUS: 'SET_AGENT_DELETING_STATUS',
  SET_AGENTS: 'SET_AGENTS',
  ADD_AGENT: 'ADD_AGENT',
  EDIT_AGENT: 'EDIT_AGENT',
  DELETE_AGENT: 'DELETE_AGENT',
  UPDATE_AGENTS_PRESENCE: 'UPDATE_AGENTS_PRESENCE',
  UPDATE_SINGLE_AGENT_PRESENCE: 'UPDATE_SINGLE_AGENT_PRESENCE',

  // New Agent
  SET_NEW_AGENTS: 'SET_NEW_AGENTS',
  SET_NEW_AGENT_FETCHING_STATUS: 'SET_NEW_AGENT_FETCHING_STATUS',
  ADD_NEW_AGENTS: 'ADD_NEW_AGENTS',
  EDIT_NEW_AGENTS: 'EDIT_NEW_AGENTS',
  DELETE_NEW_AGENTS: 'DELETE_NEW_AGENTS',

  // Canned Response
  SET_CANNED_UI_FLAG: 'SET_CANNED_UI_FLAG',
  SET_CANNED: 'SET_CANNED',
  ADD_CANNED: 'ADD_CANNED',
  EDIT_CANNED: 'EDIT_CANNED',
  DELETE_CANNED: 'DELETE_CANNED',

  // Roles
  SET_ROLE_UI_FLAG: 'SET_ROLE_UI_FLAG',
  SET_ROLE: 'SET_ROLE',
  ADD_ROLE: 'ADD_ROLE',
  EDIT_ROLE: 'EDIT_ROLE',
  DELETE_ROLE: 'DELETE_ROLE',

  // File Upload
  SET_FILE_UI_FLAG: 'SET_FILE_UI_FLAG',
  SET_FILE: 'SET_FILE',
  ADD_FILE: 'ADD_FILE',
  EDIT_FILE: 'EDIT_FILE',
  DELETE_FILE: 'DELETE_FILE',

  // Labels
  SET_LABEL_UI_FLAG: 'SET_LABEL_UI_FLAG',
  SET_LABELS: 'SET_LABELS',
  ADD_LABEL: 'ADD_LABEL',
  EDIT_LABEL: 'EDIT_LABEL',
  DELETE_LABEL: 'DELETE_LABEL',

  // Integrations
  SET_INTEGRATIONS_UI_FLAG: 'SET_INTEGRATIONS_UI_FLAG',
  SET_INTEGRATIONS: 'SET_INTEGRATIONS',
  ADD_INTEGRATION: 'ADD_INTEGRATION',
  DELETE_INTEGRATION: 'DELETE_INTEGRATION',
  ADD_INTEGRATION_HOOKS: 'ADD_INTEGRATION_HOOKS',
  DELETE_INTEGRATION_HOOKS: 'DELETE_INTEGRATION_HOOKS',

  // WebHook
  SET_WEBHOOK_UI_FLAG: 'SET_WEBHOOK_UI_FLAG',
  SET_WEBHOOK: 'SET_WEBHOOK',
  ADD_WEBHOOK: 'ADD_WEBHOOK',
  DELETE_WEBHOOK: 'DELETE_WEBHOOK',
  UPDATE_WEBHOOK: 'UPDATE_WEBHOOK',

  // Contacts
  SET_CONTACT_META: 'SET_CONTACT_META',
  SET_CONTACT_UI_FLAG: 'SET_CONTACT_UI_FLAG',
  SET_CONTACT_ITEM: 'SET_CONTACT_ITEM',
  SET_CONTACTS: 'SET_CONTACTS',
  CLEAR_CONTACTS: 'CLEAR_CONTACTS',
  EDIT_CONTACT: 'EDIT_CONTACT',
  DELETE_CONTACT: 'DELETE_CONTACT',
  UPDATE_CONTACTS_PRESENCE: 'UPDATE_CONTACTS_PRESENCE',
  SET_CONTACT_FILTERS: 'SET_CONTACT_FILTERS',
  CLEAR_CONTACT_FILTERS: 'CLEAR_CONTACT_FILTERS',
  DELETE_CONTACTS: 'DELETE_CONTACTS',
  SET_IMPORT_STATUS: 'SET_IMPORT_STATUS',
  SET_IMPORT_CONFIG: 'SET_IMPORT_CONFIG',
  RESET_IMPORT_INFO: 'RESET_IMPORT_INFO',

  // Notifications
  SET_NOTIFICATIONS_META: 'SET_NOTIFICATIONS_META',
  SET_NOTIFICATIONS_UNREAD_COUNT: 'SET_NOTIFICATIONS_UNREAD_COUNT',
  SET_NOTIFICATIONS_UI_FLAG: 'SET_NOTIFICATIONS_UI_FLAG',
  UPDATE_NOTIFICATION: 'UPDATE_NOTIFICATION',
  ADD_NOTIFICATION: 'ADD_NOTIFICATION',
  ADD_FLOATING_NOTIFICATION: 'ADD_FLOATING_NOTIFICATION',
  REMOVE_FLOATING_NOTIFICATION: 'REMOVE_FLOATING_NOTIFICATION',
  UPDATE_ALL_NOTIFICATIONS: 'UPDATE_ALL_NOTIFICATIONS',
  SET_NOTIFICATIONS_ITEM: 'SET_NOTIFICATIONS_ITEM',
  SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
  CLEAR_NOTIFICATIONS: 'CLEAR_NOTIFICATIONS',
  EDIT_NOTIFICATIONS: 'EDIT_NOTIFICATIONS',
  UPDATE_NOTIFICATIONS_PRESENCE: 'UPDATE_NOTIFICATIONS_PRESENCE',

  // Contact Conversation
  SET_CONTACT_CONVERSATIONS_UI_FLAG: 'SET_CONTACT_CONVERSATIONS_UI_FLAG',
  SET_CONTACT_CONVERSATIONS: 'SET_CONTACT_CONVERSATIONS',
  ADD_CONTACT_CONVERSATION: 'ADD_CONTACT_CONVERSATION',
  DELETE_CONTACT_CONVERSATION: 'DELETE_CONTACT_CONVERSATION',

  // Contact Label
  SET_CONTACT_LABELS_UI_FLAG: 'SET_CONTACT_LABELS_UI_FLAG',
  SET_CONTACT_LABELS: 'SET_CONTACT_LABELS',

  // Conversation Label
  SET_CONVERSATION_LABELS_UI_FLAG: 'SET_CONVERSATION_LABELS_UI_FLAG',
  SET_CONVERSATION_LABELS: 'SET_CONVERSATION_LABELS',
  SET_BULK_CONVERSATION_LABELS: 'SET_BULK_CONVERSATION_LABELS',

  // Reports
  SET_ACCOUNT_REPORTS: 'SET_ACCOUNT_REPORTS',
  SET_HEATMAP_DATA: 'SET_HEATMAP_DATA',
  TOGGLE_HEATMAP_LOADING: 'TOGGLE_HEATMAP_LOADING',
  SET_ACCOUNT_SUMMARY: 'SET_ACCOUNT_SUMMARY',
  TOGGLE_ACCOUNT_REPORT_LOADING: 'TOGGLE_ACCOUNT_REPORT_LOADING',
  SET_ACCOUNT_CONVERSATION_METRIC: 'SET_ACCOUNT_CONVERSATION_METRIC',
  TOGGLE_ACCOUNT_CONVERSATION_METRIC_LOADING:
    'TOGGLE_ACCOUNT_CONVERSATION_METRIC_LOADING',
  SET_AGENT_CONVERSATION_METRIC: 'SET_AGENT_CONVERSATION_METRIC',
  TOGGLE_AGENT_CONVERSATION_METRIC_LOADING:
    'TOGGLE_AGENT_CONVERSATION_METRIC_LOADING',

  // Conversation Logs
  SET_CONVERSATION_LOG: 'SET_CONVERSATION_LOG',
  TOGGLE_CONVERSATION_LOG: 'TOGGLE_CONVERSATION_LOG',

  // Agent Logs
  SET_AGENT_LOG: 'SET_AGENT_LOG',
  TOGGLE_AGENT_LOG: 'TOGGLE_AGENT_LOG',

  // Agent Logs
  SET_AGENT_SESSION_LOG: 'SET_AGENT_SESSION_LOG',
  TOGGLE_AGENT_SESSION_LOG: 'TOGGLE_AGENT_SESSION_LOG',

  // Reports Conversation Logs
  SET_REPORTS_CONVERSATION_LOG: 'SET_REPORTS_CONVERSATION_LOG',
  SET_OPENED_CONVERSATION_LOG: 'SET_OPENED_CONVERSATION_LOG',
  TOGGLE_REPORTS_CONVERSATION_LOG: 'TOGGLE_REPORTS_CONVERSATION_LOG',
  TOGGLE_OPENED_CONVERSATION_LOG: 'TOGGLE_OPENED_CONVERSATION_LOG',
  SET_CLOSED_CONVERSATION_LOG: 'SET_CLOSED_CONVERSATION_LOG',
  TOGGLE_CLOSED_CONVERSATION_LOG: 'TOGGLE_CLOSED_CONVERSATION_LOG',
  SET_CLOSED_CONVERSATION_LOG_BY_CATEGORY:
    'SET_CLOSED_CONVERSATION_LOG_BY_CATEGORY',
  TOGGLE_CLOSED_CONVERSATION_LOG_BY_CATEGORY:
    'TOGGLE_CLOSED_CONVERSATION_LOG_BY_CATEGORY',
  SET_REPORTS_METRIC_SUMMARY: 'SET_REPORTS_METRIC_SUMMARY',
  TOGGLE_REPORTS_METRIC_SUMMARY: 'TOGGLE_REPORTS_METRIC_SUMMARY',
  SET_OPENED_CONVERSATION_SUMMARY: 'SET_OPENED_CONVERSATION_SUMMARY',
  TOGGLE_OPENED_CONVERSATION_SUMMARY: 'TOGGLE_OPENED_CONVERSATION_SUMMARY',
  SET_OPENED_CONVERSATION_SUMMARY_BY_SOURCE:
    'SET_OPENED_CONVERSATION_SUMMARY_BY_SOURCE',
  TOGGLE_OPENED_CONVERSATION_SUMMARY_BY_SOURCE:
    'TOGGLE_OPENED_CONVERSATION_SUMMARY_BY_SOURCE',

  SET_REPORTS_CONVERSATION_PERFORMANCE: 'SET_REPORTS_CONVERSATION_PERFORMANCE',
  TOGGLE_REPORTS_CONVERSATION_PERFORMANCE:
    'TOGGLE_REPORTS_CONVERSATION_PERFORMANCE',

  SET_REPORTS_METRIC_PERFORMANCE: 'SET_REPORTS_METRIC_PERFORMANCE',
  TOGGLE_REPORTS_METRIC_PERFORMANCE: 'TOGGLE_REPORTS_METRIC_PERFORMANCE',

  // Conversation Metadata
  SET_CONVERSATION_METADATA: 'SET_CONVERSATION_METADATA',

  // Conversation Page
  SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
  SET_CONVERSATION_END_REACHED: 'SET_CONVERSATION_END_REACHED',
  CLEAR_CONVERSATION_PAGE: 'CLEAR_CONVERSATION_PAGE',

  // Notification Settings
  SET_USER_NOTIFICATION_UI_FLAG: 'SET_USER_NOTIFICATION_UI_FLAG',
  SET_USER_NOTIFICATION: 'SET_USER_NOTIFICATION',

  // User Typing
  ADD_USER_TYPING_TO_CONVERSATION: 'ADD_USER_TYPING_TO_CONVERSATION',
  REMOVE_USER_TYPING_FROM_CONVERSATION: 'REMOVE_USER_TYPING_FROM_CONVERSATION',

  // Conversation Search
  SEARCH_CONVERSATIONS_SET: 'SEARCH_CONVERSATIONS_SET',
  SEARCH_CONVERSATIONS_RESET: 'SEARCH_CONVERSATIONS_RESET',
  SEARCH_HAVE_MORE_SET: 'SEARCH_HAVE_MORE_SET',
  SEARCH_CONVERSATIONS_SET_UI_FLAG: 'SEARCH_CONVERSATIONS_SET_UI_FLAG',

  // Campaigns
  SET_CAMPAIGN_UI_FLAG: 'SET_CAMPAIGN_UI_FLAG',
  SET_CAMPAIGNS: 'SET_CAMPAIGNS',
  APPEND_CAMPAIGNS: 'APPEND_CAMPAIGNS',
  ADD_CAMPAIGN: 'ADD_CAMPAIGN',
  EDIT_CAMPAIGN: 'EDIT_CAMPAIGN',
  DELETE_CAMPAIGN: 'DELETE_CAMPAIGN',

  SET_CAMPAIGN_FETCHING_STATUS: 'SET_CAMPAIGN_FETCHING_STATUS',
  SET_CAMPAIGN_CREATING_STATUS: 'SET_CAMPAIGN_CREATING_STATUS',
  SET_CAMPAIGN_UPDATING_STATUS: 'SET_CAMPAIGN_UPDATING_STATUS',
  SET_CAMPAIGN_DELETING_STATUS: 'SET_CAMPAIGN_DELETING_STATUS',

  // Contact notes
  SET_CONTACT_NOTES_UI_FLAG: 'SET_CONTACT_NOTES_UI_FLAG',
  SET_CONTACT_NOTES: 'SET_CONTACT_NOTES',
  ADD_CONTACT_NOTE: 'ADD_CONTACT_NOTE',
  EDIT_CONTACT_NOTE: 'EDIT_CONTACT_NOTE',
  DELETE_CONTACT_NOTE: 'DELETE_CONTACT_NOTE',

  // Contact reports
  SET_CONTACTS_REPORTS_UI_FLAG: 'SET_CONTACTS_REPORTS_UI_FLAG',
  SET_CONTACT_REPORTS_LOGS_SUMMARY: 'SET_CONTACT_REPORTS_LOGS_SUMMARY',
  SET_CONTACT_REPORTS_CONTACT_LOGS_CHART:
    'SET_CONTACT_REPORTS_CONTACT_LOGS_CHART',
  SET_CONTACT_REPORTS_ADDED_CONTACT_LOGS_CHART:
    'SET_CONTACT_REPORTS_ADDED_CONTACT_LOGS_CHART',
  SET_CONTACT_REPORTS_TABLE_DATA: 'SET_CONTACT_REPORTS_TABLE_DATA',
  SET_CONTACT_REPORTS_CONTACT_LOGS_COUNT:
    'SET_CONTACT_REPORTS_CONTACT_LOGS_COUNT',

  // logs reports
  SET_LOGS_REPORTS_UI_FLAG: 'SET_LOGS_REPORTS_UI_FLAG',
  SET_ASSIGNMENT_LOGS: 'SET_ASSIGNMENT_LOGS',
  SET_COMMENT_LOGS: 'SET_COMMENT_LOGS',

  // custom reports
  SET_FILTER_PARAMS: 'SET_FILTER_PARAMS',

  // CSAT Responses
  SET_CSAT_RESPONSE_UI_FLAG: 'SET_CSAT_RESPONSE_UI_FLAG',
  SET_CSAT_RESPONSE: 'SET_CSAT_RESPONSE',
  SET_CSAT_RESPONSE_METRICS: 'SET_CSAT_RESPONSE_METRICS',

  // Custom Attributes
  SET_CUSTOM_ATTRIBUTE_UI_FLAG: 'SET_CUSTOM_ATTRIBUTE_UI_FLAG',
  SET_CUSTOM_ATTRIBUTE: 'SET_CUSTOM_ATTRIBUTE',
  ADD_CUSTOM_ATTRIBUTE: 'ADD_CUSTOM_ATTRIBUTE',
  EDIT_CUSTOM_ATTRIBUTE: 'EDIT_CUSTOM_ATTRIBUTE',
  DELETE_CUSTOM_ATTRIBUTE: 'DELETE_CUSTOM_ATTRIBUTE',

  // Automations
  SET_AUTOMATION_UI_FLAG: 'SET_AUTOMATION_UI_FLAG',
  SET_AUTOMATIONS: 'SET_AUTOMATIONS',
  ADD_AUTOMATION: 'ADD_AUTOMATION',
  EDIT_AUTOMATION: 'EDIT_AUTOMATION',
  DELETE_AUTOMATION: 'DELETE_AUTOMATION',

  // Workflows
  SET_WORKFLOW_UI_FLAG: 'SET_WORKFLOW_UI_FLAG',
  SET_WORKFLOWS: 'SET_WORKFLOWS',
  SET_Triggerables_WORKFLOWS: 'SET_Triggerables_WorkFlows',
  ADD_WORKFLOW: 'ADD_WORKFLOW',
  ADD_SINGLE_WORKFLOW: 'ADD_SINGLE_WORKFLOW',
  EDIT_WORKFLOW: 'EDIT_WORKFLOW',
  DELETE_WORKFLOW: 'DELETE_WORKFLOW',
  WORKFLOW_TRIGGER_WEBHOOK: 'WORKFLOW_TRIGGER_WEBHOOK',
  SET_WORKFLOW_TRIGGER_WEBHOOK: 'SET_WORKFLOW_TRIGGER_WEBHOOK',

  SET_WORKFLOW_TEMPLATES: 'SET_WORKFLOW_TEMPLATES',
  SET_WORKFLOW_SINGLE_TEMPLATE: 'SET_WORKFLOW_SINGLE_TEMPLATE',
  SET_WORKFLOW_TEMPLATES_UI_FLAG: 'SET_WORKFLOW_TEMPLATES_UI_FLAG',

  // Custom Views
  SET_CUSTOM_VIEW_UI_FLAG: 'SET_CUSTOM_VIEW_UI_FLAG',
  SET_CUSTOM_VIEW: 'SET_CUSTOM_VIEW',
  ADD_CUSTOM_VIEW: 'ADD_CUSTOM_VIEW',
  UPDATE_CUSTOM_VIEW: 'UPDATE_CUSTOM_VIEW',
  DELETE_CUSTOM_VIEW: 'DELETE_CUSTOM_VIEW',

  // Bulk Actions
  SET_BULK_ACTIONS_FLAG: 'SET_BULK_ACTIONS_FLAG',

  // Dashboard Apps
  SET_DASHBOARD_APPS_UI_FLAG: 'SET_DASHBOARD_APPS_UI_FLAG',
  SET_DASHBOARD_APPS: 'SET_DASHBOARD_APPS',
  CREATE_DASHBOARD_APP: 'CREATE_DASHBOARD_APP',
  EDIT_DASHBOARD_APP: 'EDIT_DASHBOARD_APP',
  DELETE_DASHBOARD_APP: 'DELETE_DASHBOARD_APP',

  // Help center
  ADD_ARTICLE: 'ADD_ARTICLE',
  ADD_ARTICLE_ID: 'ADD_ARTICLE_ID',
  ADD_MANY_ARTICLES: 'ADD_MANY_ARTICLES',
  ADD_MANY_ARTICLES_ID: 'ADD_MANY_ARTICLES_ID',
  SET_ARTICLES_META: 'SET_ARTICLES_META',
  UPDATE_ARTICLE_FLAG: 'UPDATE_ARTICLE_FLAG',
  ADD_ARTICLE_FLAG: 'ADD_ARTICLE_FLAG',
  UPDATE_ARTICLE: 'UPDATE_ARTICLE',
  CLEAR_ARTICLES: 'CLEAR_ARTICLES',
  REMOVE_ARTICLE: 'REMOVE_ARTICLE',
  REMOVE_ARTICLE_ID: 'REMOVE_ARTICLE_ID',
  SET_UI_FLAG: 'SET_UI_FLAG',

  // Help Center -- Categories
  ADD_CATEGORY: 'ADD_CATEGORY',
  ADD_CATEGORY_ID: 'ADD_CATEGORY_ID',
  ADD_MANY_CATEGORIES: 'ADD_MANY_CATEGORIES',
  ADD_MANY_CATEGORIES_ID: 'ADD_MANY_CATEGORIES_ID',
  ADD_CATEGORY_FLAG: 'ADD_CATEGORY_FLAG',
  CLEAR_CATEGORIES: 'CLEAR_CATEGORIES',
  UPDATE_CATEGORY: 'UPDATE_CATEGORY',
  REMOVE_CATEGORY: 'REMOVE_CATEGORY',
  REMOVE_CATEGORY_ID: 'REMOVE_CATEGORY_ID',

  // Agent Bots
  SET_AGENT_BOT_UI_FLAG: 'SET_AGENT_BOT_UI_FLAG',
  SET_AGENT_BOTS: 'SET_AGENT_BOTS',
  ADD_AGENT_BOT: 'ADD_AGENT_BOT',
  EDIT_AGENT_BOT: 'EDIT_AGENT_BOT',
  DELETE_AGENT_BOT: 'DELETE_AGENT_BOT',
  SET_AGENT_BOT_INBOX: 'SET_AGENT_BOT_INBOX',

  // MACROS
  SET_MACROS_UI_FLAG: 'SET_MACROS_UI_FLAG',
  SET_MACROS: 'SET_MACROS',
  ADD_MACRO: 'ADD_MACRO',
  EDIT_MACRO: 'EDIT_MACRO',
  DELETE_MACRO: 'DELETE_MACRO',

  // Full Search
  FULL_SEARCH_SET: 'FULL_SEARCH_SET',
  CONTACT_SEARCH_SET: 'CONTACT_SEARCH_SET',
  CONTACT_SEARCH_SET_UI_FLAG: 'CONTACT_SEARCH_SET_UI_FLAG',
  CONVERSATION_SEARCH_SET: 'CONVERSATION_SEARCH_SET',
  CONVERSATION_SEARCH_SET_UI_FLAG: 'CONVERSATION_SEARCH_SET_UI_FLAG',
  MESSAGE_SEARCH_SET: 'MESSAGE_SEARCH_SET',
  MESSAGE_SEARCH_SET_UI_FLAG: 'MESSAGE_SEARCH_SET_UI_FLAG',
  FULL_SEARCH_SET_UI_FLAG: 'FULL_SEARCH_SET_UI_FLAG',
  SET_CONVERSATION_PARTICIPANTS_UI_FLAG:
    'SET_CONVERSATION_PARTICIPANTS_UI_FLAG',
  SET_CONVERSATION_PARTICIPANTS: 'SET_CONVERSATION_PARTICIPANTS',

  // Audit Logs
  SET_AUDIT_LOGS_UI_FLAG: 'SET_AUDIT_LOGS_UI_FLAG',
  SET_AUDIT_LOGS: 'SET_AUDIT_LOGS',
  SET_AUDIT_LOGS_META: 'SET_AUDIT_LOGS_META',

  // Broadcast
  SET_BROADCAST_CAMPAIGN: 'SET_BROADCAST_CAMPAIGN',
  SET_BROADCAST_ENGAGEMENT_CAMPAIGN: 'SET_BROADCAST_ENGAGEMENT_CAMPAIGN',
  TOGGLE_BROADCAST_CAMPAIGNS: 'TOGGLE_BROADCAST_CAMPAIGNS',
  TOGGLE_BROADCAST_REPORTS: 'TOGGLE_BROADCAST_REPORTS',
  TOGGLE_BROADCAST_CAMPAIGNS_DOWNLOAD: 'TOGGLE_BROADCAST_CAMPAIGNS_DOWNLOAD',
  SET_BROADCAST_REPORT: 'SET_BROADCAST_REPORT',

  // Messages
  SET_MESSAGE_INCOMING: 'SET_MESSAGE_INCOMING',
  TOGGLE_MESSAGE_INCOMING: 'TOGGLE_MESSAGE_INCOMING',
  SET_DOWNLOAD_MESSAGE_INCOMING: 'SET_DOWNLOAD_MESSAGE_INCOMING',
  TOGGLE_DOWNLOAD_MESSAGE_INCOMING: 'TOGGLE_DOWNLOAD_MESSAGE_INCOMING',

  SET_MESSAGE_OUT_GOING: 'SET_MESSAGE_OUT_GOING',
  TOGGLE_MESSAGE_OUT_GOING: 'TOGGLE_MESSAGE_OUT_GOING',
  SET_DOWNLOAD_MESSAGE_OUT_GOING: 'SET_DOWNLOAD_MESSAGE_OUT_GOING',
  TOGGLE_DOWNLOAD_MESSAGE_OUT_GOING: 'TOGGLE_DOWNLOAD_MESSAGE_OUT_GOING',

  SET_MESSAGE_OUT_GOING_METRICS: 'SET_MESSAGE_OUT_GOING_METRICS',
  TOGGLE_MESSAGE_OUT_GOING_METRICS: 'TOGGLE_MESSAGE_OUT_GOING_METRICS',

  SET_MESSAGE_INCOMING_METRICS: 'SET_MESSAGE_INCOMING_METRICS',
  TOGGLE_MESSAGE_INCOMING_METRICS: 'TOGGLE_MESSAGE_INCOMING_METRICS',

  SET_MESSAGE_FAILED: 'SET_MESSAGE_FAILED',
  TOGGLE_MESSAGE_FAILED: 'TOGGLE_MESSAGE_FAILED',
  SET_DOWNLOAD_MESSAGE_FAILED: 'SET_DOWNLOAD_MESSAGE_FAILED',
  TOGGLE_DOWNLOAD_MESSAGE_FAILED: 'TOGGLE_DOWNLOAD_MESSAGE_FAILED',

  SET_MESSAGE_SUMMARY: 'SET_MESSAGE_SUMMARY',
  TOGGLE_MESSAGE_SUMMARY: 'TOGGLE_MESSAGE_SUMMARY',
  // Get CAMPAIGNS whatsApp
  TOGGLE_WHATS_APP_CAMPAIGNS: 'TOGGLE_WHATS_APP_CAMPAIGNS',
  SET_WHATS_APP_CAMPAIGNS: 'SET_WHATS_APP_CAMPAIGNS',
  ADD_WHATS_APP_CAMPAIGNS: 'ADD_WHATS_APP_CAMPAIGNS',
  EDIT_WHATS_APP_CAMPAIGNS: 'EDIT_WHATS_APP_CAMPAIGNS',
  DELETE_WHATS_APP_CAMPAIGNS: 'DELETE_WHATS_APP_CAMPAIGNS',
  SET_CAMPAIGNS_FETCHING_STATUS: 'SET_CAMPAIGNS_FETCHING_STATUS',
  SET_CAMPAIGNS_CREATING_STATUS: 'SET_CAMPAIGNS_CREATING_STATUS',
  SET_CAMPAIGNS_UPDATING_STATUS: 'SET_CAMPAIGNS_UPDATING_STATUS',
  SET_CAMPAIGNS_DELETING_STATUS: 'SET_CAMPAIGNS_DELETING_STATUS',

  // shortcuts
  SET_SHORTCUTS_UI_FLAG: 'SET_SHORTCUTS_UI_FLAG',
  SET_SHORTCUTS: 'SET_SHORTCUTS',

  // salla
  SET_EVENT_UI_FLAG: 'SET_EVENT_UI_FLAG',
  SET_SALLA_INBOXES: 'SET_SALLA_INBOXES',
  SET_SALLA_CONFIGURATION: 'SET_SALLA_CONFIGURATION',
  SET_SALLA_STATUS: 'SET_SALLA_STATUS',
  SET_ALL_EVENTS: 'SET_ALL_EVENTS',
  SET_EVENT: 'SET_EVENT',
  EDIT_EVENT: 'EDIT_EVENT',
  SET_SALLA_ORDERS: 'SET_SALLA_ORDERS',
  SET_SALLA_PRODUCTS: 'SET_SALLA_PRODUCTS',
  SET_SALLA_CUSTOMERS: 'SET_SALLA_CUSTOMERS',
  SET_SEARCH_SALLA_CUSTOMERS: 'SET_SEARCH_SALLA_CUSTOMERS',
  SET_SALLA_LINKED_CUSTOMERS: 'SET_SALLA_LINKED_CUSTOMERS',
  EDIT_SALLA_CUSTOMER: 'EDIT_SALLA_CUSTOMER',
  CLEAR_SALLA_CUSTOMERS: 'CLEAR_SALLA_CUSTOMERS',
  SET_SALLA_ORDER_DETAILS: 'SET_SALLA_ORDER_DETAILS',
  SET_SALLA_ORDER_STATUSES: 'SET_SALLA_ORDER_STATUSES',
  SET_SALLA_UPDATE_ORDER_STATUSES: 'SET_SALLA_UPDATE_ORDER_STATUSES',

  // subscription
  SET_SUBSCRIPTION_UI_FLAG: 'SET_SUBSCRIPTION_UI_FLAG',
  SET_SUBSCRIPTION_INFO: 'SET_SUBSCRIPTION_INFO',
  SET_SUBSCRIPTIONS: 'SET_SUBSCRIPTIONS',
  SET_SUBSCRIPTION_ADDONS: 'SET_SUBSCRIPTION_ADDONS',
  CALC_SUBSCRIPTION_REMAINING_DAYS: 'CALC_SUBSCRIPTION_REMAINING_DAYS',
  CALC_ADDONS_REMAINING_QUOTA: 'CALC_ADDONS_REMAINING_QUOTA',

  // Beva smart AI
  SET_BEVA_AI_UI_FLAG: 'SET_BEVA_AI_UI_FLAG',
  SET_KNOWLEDGE_SOURCE: 'SET_KNOWLEDGE_SOURCE',
  ADD_KNOWLEDGE: 'ADD_KNOWLEDGE',
  EDIT_KNOWLEDGE: 'EDIT_KNOWLEDGE',
  DELETE_KNOWLEDGE: 'DELETE_KNOWLEDGE',
  SET_COMPLETED_KNOWLEDGE_SOURCE: 'SET_COMPLETED_KNOWLEDGE_SOURCE',

  // Facebook(instagram) pages
  SET_FACEBOOK_UI_FLAG: 'SET_FACEBOOK_UI_FLAG',
  SET_FACEBOOK_PAGES: 'SET_FACEBOOK_PAGES',
  SET_FACEBOOK_POSTS: 'SET_FACEBOOK_POSTS',
  CLEAR_FACEBOOK_POSTS: 'CLEAR_FACEBOOK_POSTS',

  //uiChanges
  SET_OPEN_DROPDOWN: 'SET_OPEN_DROPDOWN',

  // zoho
  SET_ZOHO_UI_FLAG: 'SET_ZOHO_UI_FLAG',
  SET_ZOHO_CONTACTS: 'SET_ZOHO_CONTACTS',
  SET_ZOHO_LINKED_CONTACTS: 'SET_ZOHO_LINKED_CONTACTS',
  EDIT_ZOHO_CONTACT: 'EDIT_ZOHO_CONTACT',
  GET_ZOHO_TICKETS: 'GET_ZOHO_TICKETS',
  GET_ZOHO_DEPARTMENTS: 'GET_ZOHO_DEPARTMENTS',
  SET_TICKET: 'SET_TICKET',
  CREATE_TICKET: 'CREATE_TICKET',
  EDIT_TICKET: 'EDIT_TICKET',
};
