import { frontendURL } from '../../../helper/URLHelper';
import account from '../newSettings/account/account.routes';
import blockList from '../newSettings/blockList/blockList.routes';
import agent from '../newSettings/agents/agent.routes';
import agentBot from './agentBots/agentBot.routes';
import attributes from '../newSettings/attributes/attributes.routes';
import automation from './automation/automation.routes';
import auditlogs from '../newSettings/auditlogs/audit.routes';
import billing from './billing/billing.routes';
import canned from '../newSettings/canned/canned.routes';
import inbox from '../newSettings/inbox/inbox.routes';
// import integrationapps from '../newSettings/integrationapps/integrations.routes';
import integrations from '../newSettings/integrations/integrations.routes';
import labels from '../newSettings/labels/labels.routes';
import macros from '../newSettings//macros/macros.routes';
import profile from '../newSettings/profile/profile.routes';
import reports from './reports/reports.routes';
import store from '../../../store';
import teams from '../newSettings/teams/teams.routes';
import roles from '../newSettings/roles/roles.routes';
import subscription from '../newSettings/subscription/subscription.routes';
import ai from '../newSettings/beva smart AI/ai.routes';
import workflows from './workflows/workflows.routes';
import closingnotes from '../newSettings/closingnotes/closingnotes.routes';
import cannedfiles from '../newSettings/cannedfiles/cannedfiles.routes';
import Index from './Index';
import profileSettings from '../newSettings/profile/Index.vue';
export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings'),
      roles: ['administrator', 'agent'],
      component: () => import('../newSettings/Wrapper.vue'),
      props: {
        headerTitle: 'PROFILE_SETTINGS.TITLE',
        icon: 'person',
      },
      showNewButton: false,
      children: [
        {
          path: '',
          name: 'settings_home',
          component: () => import('../newSettings/profile/Index.vue'),
          roles: ['administrator', 'agent'],
        },
      ],
    },
    ...blockList.routes,
    ...account.routes,
    ...agent.routes,
    // ...agentBot.routes,
    ...attributes.routes,
    // ...automation.routes,
    ...auditlogs.routes,
    ...billing.routes,
    ...canned.routes,
    ...inbox.routes,
    // ...integrationapps.routes,
    ...integrations.routes,
    ...labels.routes,
    ...macros.routes,
    ...profile.routes,
    ...reports.routes,
    ...teams.routes,
    ...roles.routes,
    ...workflows.routes,
    ...closingnotes.routes,
    ...cannedfiles.routes,
    ...subscription.routes,
    ...ai.routes,
  ],
};
