import { frontendURL } from '../../../../helper/URLHelper';
import channelFactory from './channel-factory';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/inboxes'),
      component: () => import('../Wrapper'),
      props: params => {
        const showBackButton = params.name !== 'settings_inbox_list';
        return {
          headerTitle: 'INBOX_MGMT.HEADER',
          icon: 'mail-inbox-all',
          showBackButton,
        };
      },
      children: [
        {
          path: '',
          name: 'settings_inbox',
          redirect: 'list',
        },
        {
          path: ':inboxId/add-template',
          name: 'settings_inboxes_add_template',
          roles: ['administrator'],
          component: () => import('./AddTemplate'),
        },
        {
          path: 'list',
          name: 'settings_inbox_list',
          component: () => import('./Index'),
          meta: {
            permission: 'show_inboxes',
          },
        },
        {
          path: 'new',
          component: () => import('./InboxChannels'),
          children: [
            {
              path: '',
              name: 'settings_inbox_new',
              component: () => import('./ChannelList'),
              roles: ['administrator'],
            },
            {
              path: ':inbox_id/finish',
              name: 'settings_inbox_finish',
              component: () => import('./FinishSetup'),
              roles: ['administrator'],
            },
            {
              path: ':sub_page',
              name: 'settings_inboxes_page_channel',
              component: channelFactory.create(),
              roles: ['administrator'],
              props: route => {
                return { channel_name: route.params.sub_page };
              },
            },
            {
              path: ':inbox_id/agents',
              name: 'settings_inboxes_add_agents',
              roles: ['administrator'],
              component: () => import('./AddAgents'),
            },
          ],
        },
        {
          path: ':inboxId',
          component: () => import('./Settings'),
          roles: ['administrator'],
          children: [
            {
              path: '',
              name: 'settings_inbox_show',
              redirect: 'settings',
            },
            {
              path: 'settings',
              name: 'settings_inbox_settings',
              component: () => import('./inboxSettings.vue'),
            },
            {
              path: 'collaborators',
              name: 'settings_inbox_collaborators',
              component: () => import('./settingsPage/CollaboratorsPage'),
            },
            {
              path: 'configuration',
              name: 'settings_inbox_configuration',
              component: () => import('./settingsPage/ConfigurationPage'),
            },
            {
              path: 'preChatForm',
              name: 'settings_inbox_preChatForm',
              component: () => import('./PreChatForm/Settings'),
            },
            {
              path: 'widgetBuilder',
              name: 'settings_inbox_widgetBuilder',
              component: () => import('./WidgetBuilder'),
            },
            {
              path: 'templates-list',
              name: 'settings_inbox_templates',
              component: () => import('./components/TemplatesList.vue'),
            },
            {
              path: 'commerce',
              name: 'settings_inbox_commerceSettings',
              component: () => import('./components/CommerceSettings.vue'),
            },
            {
              path: 'botConfiguration',
              name: 'settings_inbox_botConfiguration',
              component: () => import('./components/BotConfiguration'),
            },
            {
              path: 'onGoing',
              name: 'settings_inbox_onGoing',
              component: () => import('./components/onGoing/OnGoing'),
            },
          ],
        },
      ],
    },
  ],
};
