import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../../mutation-types';
import NewCampaignsAPI from '../../../api/campaign/campaign';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
  },
  meta: {},
};

export const getters = {
  getCampaignsByType: _state => {
    return _state.records;
  },
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  meta(_state) {
    return _state.meta;
  },
  getLastCreatedCampaign(state) {
    return state.records[state.records.length - 1];
  },
};

export const actions = {
  getCampaignsByType: async ({ commit }, params) => {
    commit(types.default.SET_CAMPAIGNS_FETCHING_STATUS, true);
    try {
      const response = await NewCampaignsAPI.getCampaignsByType(params);

      commit(types.default.SET_WHATS_APP_CAMPAIGNS, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.default.SET_CAMPAIGNS_FETCHING_STATUS, false);
    }
  },
  create: async ({ commit }, campaignInfo) => {
    commit(types.default.SET_CAMPAIGNS_CREATING_STATUS, true);
    try {
      const response = await NewCampaignsAPI.create(campaignInfo);
      commit(types.default.ADD_WHATS_APP_CAMPAIGNS, response.data);
      commit(types.default.SET_CAMPAIGNS_CREATING_STATUS, false);
    } catch (error) {
      commit(types.default.SET_CAMPAIGNS_CREATING_STATUS, false);
      throw error;
    }
  },
  update: async ({ commit }, { id, ...campaignParams }) => {
    commit(types.default.SET_CAMPAIGNS_UPDATING_STATUS, true);
    try {
      const response = await NewCampaignsAPI.update(id, campaignParams);
      commit(types.default.EDIT_WHATS_APP_CAMPAIGNS, response.data);
      commit(types.default.SET_CAMPAIGNS_UPDATING_STATUS, false);
    } catch (error) {
      commit(types.default.SET_CAMPAIGNS_UPDATING_STATUS, false);
      throw new Error(error);
    }
  },
  delete: async ({ commit, dispatch }, { campaignId, ...campaignParams }) => {
    commit(types.default.SET_CAMPAIGNS_DELETING_STATUS, true);
    try {
      await NewCampaignsAPI.delete(campaignId);
      commit(types.default.DELETE_WHATS_APP_CAMPAIGNS, campaignId);
      dispatch('getCampaignsByType', campaignParams);
      commit(types.default.SET_CAMPAIGNS_DELETING_STATUS, false);
    } catch (error) {
      commit(types.default.SET_CAMPAIGNS_DELETING_STATUS, false);
      throw new Error(error);
    }
  },
  sendTest: async (_, params) => {
    try {
      const response = await NewCampaignsAPI.testCampaign(params);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message ||
        error?.response?.data?.error ||
        error?.message;
      throw new Error(errorMessage);
    }
  },
  sortCampaignsByType: async ({ commit }, params) => {
    commit(types.default.SET_CAMPAIGNS_FETCHING_STATUS, true);
    try {
      const response = await NewCampaignsAPI.sortCampaignsByType(params);
      commit(types.default.SET_WHATS_APP_CAMPAIGNS, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.default.SET_CAMPAIGNS_FETCHING_STATUS, false);
    }
  },
};

export const mutations = {
  [types.default.SET_CAMPAIGNS_FETCHING_STATUS](_state, status) {
    _state.uiFlags.isFetching = status;
  },
  [types.default.SET_CAMPAIGNS_CREATING_STATUS](_state, status) {
    _state.uiFlags.isCreating = status;
  },
  [types.default.SET_CAMPAIGNS_UPDATING_STATUS](_state, status) {
    _state.uiFlags.isUpdating = status;
  },
  [types.default.SET_CAMPAIGNS_DELETING_STATUS](_state, status) {
    _state.uiFlags.isDeleting = status;
  },
  [types.default.SET_WHATS_APP_CAMPAIGNS](_state, data) {
    _state.records = data.payload;
    _state.meta = data.meta;
  },
  // [types.default.SET_WHATS_APP_CAMPAIGNS]: MutationHelpers.set,
  [types.default.ADD_WHATS_APP_CAMPAIGNS]: MutationHelpers.create,
  [types.default.EDIT_WHATS_APP_CAMPAIGNS]: MutationHelpers.update,
  [types.default.DELETE_WHATS_APP_CAMPAIGNS]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
