import Vue from 'vue';

export const state = {
  AssignDropdownStatus: false,
};

export const getters = {
  getAssignDropdownStatus(_state) {
    return _state.AssignDropdownStatus;
  },
};

export const actions = {
  toggleAssignDropdown({ commit }, payload) {
    commit('toggleAssignDropdown', payload);
  },
};

export const mutations = {
  toggleAssignDropdown: (state, data) => {
    Vue.set(state, 'AssignDropdownStatus', data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
