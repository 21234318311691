import { required, minLength, maxLength } from 'vuelidate/lib/validators';

export default {
  title: {
    required,
    minLength: minLength(2),
    maxLength: maxLength(30),
  },
  description: {
    required,
    maxLength: maxLength(150)
  },
  agentListSelected : {
    required,
    minLength: minLength(1)
  },
  showOnSidebar: {},
};
