import * as types from '../mutation-types';
import ZohoAPI from '../../api/zoho';

export const state = {
  contacts: {
    records: [],
    totalRecords: 0,
  },
  linkedContact: {},
  tickets: {
    records: [],
    totalRecords: 0,
  },
  departments: [],
  selectedTicket: null,
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isGettingDownload: false,
  },
};

export const getters = {
  getContacts($state) {
    return $state.contacts;
  },
  getLinkedContact($state) {
    return $state.linkedContact;
  },
  getAllTickets($state) {
    return $state.tickets;
  },
  getTicket($state) {
    return $state.selectedTicket;
  },
  getDepartments($state) {
    return $state.departments;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
};

export const actions = {
  getSuggestContacts: async ({ commit }, contactId) => {
    commit(types.default.SET_ZOHO_UI_FLAG, { isFetching: true });
    try {
      const response = await ZohoAPI.getSuggestContacts(contactId);
      commit(types.default.SET_ZOHO_UI_FLAG, { isFetching: false });
      commit(types.default.SET_ZOHO_CONTACTS, response.data);
    } catch (error) {
      commit(types.default.SET_ZOHO_UI_FLAG, { isFetching: false });
    }
  },
  getContactsByName: async ({ commit }, payload) => {
    commit(types.default.SET_ZOHO_UI_FLAG, { isFetching: true });
    try {
      const response = await ZohoAPI.getContactsByName(payload);
      commit(types.default.SET_ZOHO_UI_FLAG, { isFetching: false });
      commit(types.default.SET_ZOHO_CONTACTS, response.data);
    } catch (error) {
      commit(types.default.SET_ZOHO_UI_FLAG, { isFetching: false });
    }
  },
  createZohoContact: async (
    { commit, dispatch },
    { bevaContactId, ...payload }
  ) => {
    commit(types.default.SET_ZOHO_UI_FLAG, { isCreating: true });
    try {
      const response = await ZohoAPI.createZohoContact(payload);
      commit(types.default.SET_ZOHO_UI_FLAG, { isCreating: false });

      const linkedContactData = {
        contactId: response.data.id,
        bevaContactId: bevaContactId,
      };
      await dispatch('linkContact', linkedContactData);
      await dispatch('getLinkedContact', linkedContactData.contactId);
    } catch (error) {
      commit(types.default.SET_ZOHO_UI_FLAG, { isCreating: false });
    }
  },
  linkContact: async ({ commit }, payload) => {
    commit(types.default.SET_ZOHO_UI_FLAG, { isFetching: true });
    try {
      await ZohoAPI.linkContact(payload);
      commit(types.default.SET_ZOHO_UI_FLAG, { isFetching: false });
    } catch (error) {
      commit(types.default.SET_ZOHO_UI_FLAG, { isFetching: false });
    }
  },
  editLinkedContact: async ({ commit }, payload) => {
    commit(types.default.SET_ZOHO_UI_FLAG, { isUpdating: true });
    try {
      const response = await ZohoAPI.editLinkedContact(payload);
      commit(types.default.SET_ZOHO_UI_FLAG, { isUpdating: false });
      commit(types.default.EDIT_ZOHO_CONTACT, response.data);
    } catch (error) {
      commit(types.default.SET_ZOHO_UI_FLAG, { isUpdating: false });
    }
  },
  unLinkContact: async ({ commit }, payload) => {
    commit(types.default.SET_ZOHO_UI_FLAG, { isFetching: true });
    try {
      await ZohoAPI.unLinkContact(payload);
      commit(types.default.SET_ZOHO_UI_FLAG, { isFetching: false });
    } catch (error) {
      commit(types.default.SET_ZOHO_UI_FLAG, { isFetching: false });
    }
  },
  getLinkedContact: async ({ commit }, contactId) => {
    commit(types.default.SET_ZOHO_UI_FLAG, { isFetching: true });
    try {
      const response = await ZohoAPI.getLinkedContact(contactId);
      commit(types.default.SET_ZOHO_UI_FLAG, { isFetching: false });
      commit(types.default.SET_ZOHO_LINKED_CONTACTS, response.data);
    } catch (error) {
      commit(types.default.SET_ZOHO_UI_FLAG, { isFetching: false });
    }
  },
  getAllTickets: async ({ commit }, params) => {
    commit(types.default.SET_ZOHO_UI_FLAG, { isFetching: true });
    try {
      const response = await ZohoAPI.getAllTickets(params);
      commit(types.default.SET_ZOHO_UI_FLAG, { isFetching: false });
      commit(types.default.GET_ZOHO_TICKETS, response.data);
    } catch (error) {
      commit(types.default.SET_ZOHO_UI_FLAG, { isFetching: false });
    }
  },
  getTicket: async ({ commit }, ticketId) => {
    commit(types.default.SET_ZOHO_UI_FLAG, { isFetching: true });
    try {
      const response = await ZohoAPI.getTicket(ticketId);
      commit(types.default.SET_ZOHO_UI_FLAG, { isFetching: false });
      commit(types.default.SET_TICKET, response.data);
    } catch (error) {
      commit(types.default.SET_ZOHO_UI_FLAG, { isFetching: false });
    }
  },
  createTicket: async ({ commit }, params) => {
    commit(types.default.SET_ZOHO_UI_FLAG, { isCreating: true });
    try {
      await ZohoAPI.createTicket(params);
      commit(types.default.SET_ZOHO_UI_FLAG, { isCreating: false });
    } catch (error) {
      commit(types.default.SET_ZOHO_UI_FLAG, { isCreating: false });
      throw error;
    }
  },
  updateTicket: async ({ commit }, { id, ...ticketParams }) => {
    commit(types.default.SET_ZOHO_UI_FLAG, { isUpdating: true });
    try {
      const response = await ZohoAPI.update({ id, ticketParams });
      commit(types.default.EDIT_TICKET, response.data);
      commit(types.default.SET_ZOHO_UI_FLAG, { isUpdating: false });
    } catch (error) {
      commit(types.default.SET_ZOHO_UI_FLAG, { isUpdating: false });
      throw error;
    }
  },
  addComment: async ({ commit }, { id, comment }) => {
    commit(types.default.SET_ZOHO_UI_FLAG, { isUpdating: true });
    try {
      ZohoAPI.addComment({ id, comment });
      commit(types.default.SET_ZOHO_UI_FLAG, { isUpdating: false });
    } catch (error) {
      commit(types.default.SET_ZOHO_UI_FLAG, { isUpdating: false });
      throw error;
    }
  },
  getDepartments: async ({ commit }) => {
    commit(types.default.SET_ZOHO_UI_FLAG, { isFetching: true });
    try {
      const response = await ZohoAPI.getDepartments();
      commit(types.default.SET_ZOHO_UI_FLAG, { isFetching: false });
      commit(types.default.GET_ZOHO_DEPARTMENTS, response.data);
    } catch (error) {
      commit(types.default.SET_ZOHO_UI_FLAG, { isFetching: false });
      throw error;
    }
  },
  getDownloadLink: async ({ commit }, attachLink) => {
    commit(types.default.SET_ZOHO_UI_FLAG, { isGettingDownload: true });
    try {
      const response = await ZohoAPI.getDownloadLink(attachLink);
      commit(types.default.SET_ZOHO_UI_FLAG, { isGettingDownload: false });
      commit(types.default.GET_ZOHO_DEPARTMENTS, response.data);
      return response.data;
    } catch (error) {
      commit(types.default.SET_ZOHO_UI_FLAG, { isGettingDownload: false });
      throw error;
    }
  },
};

export const mutations = {
  [types.default.SET_ZOHO_CONTACTS]($state, data) {
    $state.contacts.records = data.data;
    $state.contacts.totalRecords = data.count;
  },
  [types.default.SET_ZOHO_LINKED_CONTACTS]($state, data) {
    $state.linkedContact = data;
  },
  [types.default.EDIT_ZOHO_CONTACT]($state, data) {
    if ($state.contacts.records.length) {
      let contact = $state.contacts.records.find(
        contact => contact.id == data.id
      );
      if (contact) contact = data;
    }
  },
  [types.default.SET_ZOHO_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.default.GET_ZOHO_TICKETS]($state, info) {
    $state.tickets.records = info?.data || [];
    $state.tickets.totalRecords = info?.count || 0;
  },
  [types.default.SET_TICKET]($state, data) {
    $state.selectedTicket = data;
  },
  [types.default.EDIT_TICKET]($state, data) {
    let ticket = $state.tickets.records.find(ticket => ticket.id == data.id);
    Object.assign(ticket, data);
  },
  [types.default.GET_ZOHO_DEPARTMENTS]($state, { data }) {
    $state.departments = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
