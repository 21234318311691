import ApiClient from '../ApiClient';

class NewCampaignsAPI extends ApiClient {
  constructor() {
    super('campaigns', { accountScoped: true });
  }

  getCampaignsByType(prams = {}) {
    return axios.get(`${this.url}?campaign_type=${prams.campaignType}`, {
      params: {
        page: prams.pageIndex,
        status: prams.status,
        title: prams.title,
        enabled: prams.enabled,
        sort: prams.sortKey,
        inbox_id: prams.inboxId,
      },
    });
  }

  sortCampaignsByType(prams = {}) {
    return axios.get(`${this.url}?campaign_type=${prams.campaignType}`, {
      params: {
        sort: prams.sortKey,
        status: prams.status,
        title: prams.title,
        enabled: prams.enabled,
        page: prams.pageIndex,
      },
    });
  }

  testCampaign(params) {
    return axios.post(`${this.url}/test`, params);
  }
}

export default new NewCampaignsAPI();
