import ApiClient from './ApiClient';

class CampaignsAPI extends ApiClient {
  constructor() {
    super('campaigns', { accountScoped: true });
  }

  sort({ sortKey } = {}) {
    return axios.get(this.url, {
      params: {
        sort: sortKey,
      },
    });
  }

  get(prams = {}) {
    return axios.get(`${this.url}`, {
      params: {
        since: prams.since,
        until: prams.until,
        id: prams.inbox_id,
        inbox_id: prams.campaign_id,
        page: prams.pageIndexCampaigns,
        title: prams.title,
        enabled: prams.status,
        title: prams.search_key,
      },
    });
  }

  getCampaignsByType(prams = {}) {
    return axios.get(`${this.url}?campaign_type=${prams.campaignType}`, {
      params: {
        page: prams.pageIndex,
      },
    });
  }

  testCampaign(prams = {}) {
    return axios.post(`${this.url}/test`, {
      contact_id: prams.contact_id,
      message: prams.message,
      inbox_id: prams.inbox_id,
    });
  }
}

export default new CampaignsAPI();
